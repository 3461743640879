import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import useLoadingSpinner from "../hooks/useLoadingSpinner";

const images = [
  {
    original: "/images/restoran/DSC_2629.jpg",
  },
  {
    original: "/images/restoran/IMG-20190904-WA0068.jpg",
  },
  {
    original: "/images/restoran/DSC_2632.jpg",
  },
  {
    original: "/images/restoran/DSC_2641.jpg",
  },
  {
    original: "/images/restoran/20191006_123735.jpg",
  },
  {
    original: "/images/restoran/20191006_123816.jpg",
  },
  {
    original: "/images/restoran/20191006_142827.jpg",
  },
  {
    original: "/images/restoran/20200126_132321.jpg",
  },
  {
    original: "/images/restoran/20200126_132331.jpg",
  },
  {
    original: "/images/restoran/20200201_122103.jpg",
  },
  {
    original: "/images/restoran/20210703_165452.jpg",
  },
  {
    original: "/images/restoran/IMG-20200204-WA0003.jpg",
  },
  {
    original: "/images/restoran/20200814_201242.jpg",
  },
  {
    original: "/images/restoran/20201202_111302.jpg",
  },
  {
    original: "/images/restoran/20201211_130502.jpg",
  },
  {
    original: "/images/restoran/20200817_131100.jpg",
  },
  {
    original: "/images/restoran/20201208_125816.jpg",
  },
  {
    original: "/images/restoran/20201215_155523.jpg",
  },
];

export default function Restaurant() {
  const [loader, showLoader, hideLoader] = useLoadingSpinner();

  useEffect(() => {
    showLoader();

    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  return (
    <div className="restaurant">
      <div className="restaurantTitle">
        <h1>RESTORAN</h1>
      </div>
      <div>
        <div className="description">
          <p>
            <b>Tri Šešira</b> je restoran sa dugom tradicijom, osnovan daleke
            1982. godine, koji u svojoj ponudi nudi specijalitete domaće kuhinje
            uz prijatan ambijent. Restoran poseduje jednu salu, koja se može
            podeliti na dva dela, i koja maksimalno može ugostiti 100 ljudi.
            Tokom godina uspešnog rada u restoranu <b>Tri Šešira</b> je
            organizovan veliki broj poslovnih ručkova, rođendana, prezentacija i
            svečanosti raznih vrsta. Restoran je zadržao kvalitet koji
            prepoznaju gosti koji se uvek iznova vraćaju da svoje najlepše
            trenutke provedu baš kod nas.
          </p>
          <p>
            Od 2008. godine uz restoran možemo da ponudimo i prenoćište našim
            gostima. Raspolažemo sa više vrsta soba različitih namena. Moguće je
            dogovoriti doručak, ručak ili večeru u sklopu prenoćišta. U godinama
            iza nas ugostili smo veliki broj sportskih ekipa, radnika i ostalih
            grupa, i verujemo da niko iz našeg restorana nije otišao
            nezadovoljan.
          </p>
          <p>
            Restoran <b>Tri Šešira</b> možete posetiti na adresi{" "}
            <i>Narodnog fronta 189</i> u Temerinu svakog dana od 8h do 22h. Sve
            dodatne informacije vezane za restoran možete dobiti na broj
            telefona{" "}
            <b>
              <a href="tel: +381638373119">063 8373119</a>
            </b>
            . Ispod možete pogledati našu galeriju i bolje se upoznati sa
            izgledom našeg restorana.
          </p>
        </div>
        <div></div>
      </div>
      <div className="gallery">
        <div>
          <ImageGallery
            items={images}
            showBullets={true}
            showThumbnails={false}
            showPlayButton={false}
            lazyLoad={false}
          />
        </div>
      </div>
      {loader}
    </div>
  );
}
