import { Route, Switch, BrowserRouter } from "react-router-dom";

import MainNavigation from "./layout/MainNavigation";
import Contact from "./pages/Contact";
import HomePage from "./pages/HomePage";
import Rooms from "./pages/Rooms";
import Restaurant from "./pages/Restaurant";
import ToVisit from "./pages/ToVisit";
import Gallery from "./pages/Gallery";
import "./Sass/style.scss";
import Footer from "./layout/Footer";
import ScrollToTop from "./components/ScrollToTop";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#960018",
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/restoran" exact>
            <MainNavigation />
            <Restaurant />
            <Footer />
          </Route>
          <Route path="/prenociste" exact>
            <MainNavigation />
            <Rooms />
            <Footer />
          </Route>
          <Route path="/sta-posetiti" exact>
            <MainNavigation />
            <ToVisit />
            <Footer />
          </Route>
          <Route path="/kontakt" exact>
            <MainNavigation />
            <Contact />
            <Footer />
          </Route>
          <Route path="/galerija" exact>
            <MainNavigation />
            <Gallery />
            <Footer />
          </Route>
          <Route path="/">
            <MainNavigation />
            <HomePage />
            <Footer />
          </Route>
        </Switch>
      </BrowserRouter>
      <ScrollToTop />
    </MuiThemeProvider>
  );
}

export default App;
