import NavigationSharpIcon from "@material-ui/icons/NavigationSharp";

export default function ScrollToTop() {
  const scrollFunction = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <div
        onClick={() => scrollFunction()}
        style={{
          position: "fixed",
          padding: "1rem",
          bottom: "25px",
          right: "25px",
          backgroundColor: "#fff",
          borderRadius: "50px",
          border: "0.1rem solid #960018",
          zIndex: 10,
        }}
      >
        <NavigationSharpIcon color="primary" />
      </div>
    </>
  );
}
