import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import useLoadingSpinner from "../hooks/useLoadingSpinner";

const images = [
  {
    original: "/images/prenociste/DSC_2624.jpg",
  },
  {
    original: "/images/restoran/DSC_2629.jpg",
  },
  {
    original: "/images/prenociste/DSC_2604.jpg",
  },
  {
    original: "/images/restoran/IMG-20190904-WA0068.jpg",
  },
  {
    original: "/images/restoran/DSC_2632.jpg",
  },
  {
    original: "/images/restoran/DSC_2641.jpg",
  },
  {
    original: "/images/restoran/20191006_123735.jpg",
  },
  {
    original: "/images/restoran/20191006_123816.jpg",
  },
  {
    original: "/images/restoran/20191006_142827.jpg",
  },
  {
    original: "/images/restoran/20200126_132321.jpg",
  },
  {
    original: "/images/restoran/20200126_132331.jpg",
  },
  {
    original: "/images/restoran/20200201_122103.jpg",
  },
  {
    original: "/images/restoran/20210703_165452.jpg",
  },
  {
    original: "/images/restoran/IMG-20200204-WA0003.jpg",
  },
  {
    original: "/images/restoran/20200814_201242.jpg",
  },
  {
    original: "/images/restoran/20201202_111302.jpg",
  },
  {
    original: "/images/restoran/20201211_130502.jpg",
  },
  {
    original: "/images/restoran/20200817_131100.jpg",
  },
  {
    original: "/images/restoran/20201208_125816.jpg",
  },
  {
    original: "/images/restoran/20201215_155523.jpg",
  },
  {
    original: "/images/prenociste/DSC_2513.jpg",
  },
  {
    original: "/images/prenociste/DSC_2518.jpg",
  },
  {
    original: "/images/prenociste/DSC_2519.jpg",
  },
  {
    original: "/images/prenociste/DSC_2529.jpg",
  },
  {
    original: "/images/prenociste/DSC_2553.jpg",
  },
  {
    original: "/images/prenociste/DSC_2485.jpg",
  },
  {
    original: "/images/prenociste/DSC_2497.jpg",
  },
  {
    original: "/images/prenociste/DSC_2503.jpg",
  },
  {
    original: "/images/prenociste/DSC_2480.jpg",
  },
  {
    original: "/images/prenociste/DSC_2561.jpg",
  },
  {
    original: "/images/prenociste/DSC_2566.jpg",
  },
  {
    original: "/images/prenociste/DSC_2579.jpg",
  },
  {
    original: "/images/prenociste/DSC_2589.jpg",
  },
];

export default function Gallery() {
  const [loader, showLoader, hideLoader] = useLoadingSpinner();

  useEffect(() => {
    showLoader();

    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  return (
    <div className="galleryPage">
      <div className="galleryTitle">
        <h1>GALERIJA</h1>
      </div>
      <div className="images">
        <ImageGallery
          items={images}
          showBullets={true}
          showThumbnails={false}
          showPlayButton={false}
          lazyLoad={false}
        />
      </div>
      {loader}
    </div>
  );
}
