function DroneVideo() {
  return (
    <div className="droneVideo">
      <div>
        <h2>
          <i>IZ VAZDUHA</i>
        </h2>
        <p>
          Na ovom snimku dronom možete pogledati kako izgledaju naš restoran i
          prenoćište iz vazduha. Naš restoran raspolaže sa više od 15 parking
          mesta.
        </p>
      </div>
      <video
        controls
        autostart
        muted
        src={"/video/snimak-dronom.mp4"}
        type="video/mp4"
      />
    </div>
  );
}

export default DroneVideo;
