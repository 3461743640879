import PhoneIcon from "@material-ui/icons/Phone";

export default function ContactUs() {
  return (
    <>
      <div className="contactUs">
        <div className="contactUsTitle">
          <h2>
            <i>KONTAKTIRAJTE NAS</i>
          </h2>
        </div>
        <div className="contactUsItems">
          <div className="phoneNumber">
            <div className="phoneIcon">
              <PhoneIcon color="primary" fontSize="large" />
            </div>
            <div className="phoneText">
              <p>RESTORAN</p>
              <b>
                <a href="tel: +381638373119">063 8373119</a>
              </b>
            </div>
          </div>
          <div className="phoneNumber">
            <div className="phoneIcon">
              <PhoneIcon color="primary" fontSize="large" />
            </div>
            <div className="phoneText">
              <p>PRENOĆIŠTE</p>
              <b>
                <a href="tel: +381631545767">063 1545767</a>
              </b>
            </div>
          </div>
          <div className="phoneNumber">
            <div className="phoneIcon">
              <PhoneIcon color="primary" fontSize="large" />
            </div>
            <div className="phoneText">
              <p>PRENOĆIŠTE</p>
              <b>
                <a href="tel: +381691845444">069 1845444</a>
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
