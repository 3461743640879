import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const MainNavigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [active, setActive] = useState(0);

  const mobileMenuOpenHandler = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    let num = 0;

    if (window.location.href.endsWith("/restoran")) {
      num = 2;
    } else if (window.location.href.endsWith("/prenociste")) {
      num = 3;
    } else if (window.location.href.endsWith("/sta-posetiti")) {
      num = 4;
    } else if (window.location.href.endsWith("/kontakt")) {
      num = 5;
    } else if (window.location.href.endsWith("/galerija")) {
      num = 0;
    } else {
      num = 1;
    }

    setActive(num);
  }, [window.location.href]);

  const scrollFunction = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onMobileClick = () => {
    mobileMenuOpenHandler();
    scrollFunction();
  };

  return (
    <>
      <nav className="homePageNav">
        <div className="navSafeWidth">
          <img src="/images/trisesirasvg.svg" alt="Logo" />
          <div>
            <Link
              to="/"
              className={`${active == 1 ? "active" : ""}`}
              onClick={scrollFunction}
            >
              Početna
            </Link>
            <Link
              to="/restoran"
              className={`${active == 2 ? "active" : ""}`}
              onClick={scrollFunction}
            >
              Restoran
            </Link>
            <Link
              to="/prenociste"
              className={`${active == 3 ? "active" : ""}`}
              onClick={scrollFunction}
            >
              Prenoćište
            </Link>
            <Link
              to="/sta-posetiti"
              className={`${active == 4 ? "active" : ""}`}
              onClick={scrollFunction}
            >
              Šta posetiti
            </Link>
            <Link
              to="/kontakt"
              className={`${active == 5 ? "active" : ""}`}
              onClick={scrollFunction}
            >
              Kontakt
            </Link>
          </div>
          <img
            src={isMobileMenuOpen ? "./images/xmark.svg" : "./images/bars.svg"}
            alt=""
            className="mobileMenuOpenButton"
            onClick={mobileMenuOpenHandler}
          />
        </div>
      </nav>
      {isMobileMenuOpen ? (
        <div className="mobileMenu">
          <img className="logo" src="/images/trisesirasvg.svg" alt="Logo" />
          <Link to="/" onClick={onMobileClick}>
            Početna
          </Link>
          <Link to="/restoran" onClick={onMobileClick}>
            Restoran
          </Link>
          <Link to="/prenociste" onClick={onMobileClick}>
            Prenoćište
          </Link>
          <Link to="/sta-posetiti" onClick={onMobileClick}>
            Šta posetiti
          </Link>
          <Link to="/kontakt" onClick={onMobileClick}>
            Kontakt
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default MainNavigation;
