import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const Footer = () => {
  return (
    <div>
      <div className="whereWeAre">
        <div className="title">
          <h2>Gde se nalazimo?</h2>
          <h3>Narodnog fronta 189, Temerin</h3>
        </div>
        <div className="googleMaps">
          <iframe
            title="Google mapa"
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9420.679334088341!2d19.896259730544543!3d45.413680528718615!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x62c456a733aa818d!2zVHJpIMWgZcWhaXJhIEImQg!5e0!3m2!1ssr!2srs!4v1639583419072!5m2!1ssr!2srs"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
      <div className="footer">
        <div className="footerItem">
          <h1>Navigacija</h1>
          <p>
            <a href="http://localhost:3000/">Početna</a>
          </p>
          <p>
            <a href="http://localhost:3000/restoran">Restoran</a>
          </p>
          <p>
            <a href="http://localhost:3000/prenociste">Prenoćište</a>
          </p>
          <p>
            <a href="http://localhost:3000/sta-posetiti">Šta posetiti</a>
          </p>
          <p>
            <a href="http://localhost:3000/kontakt">Kontakt</a>
          </p>
        </div>
        <div className="footerItem">
          <h1>Kontakt</h1>
          <div className="locationFooter">
            <img src="./images/phone.svg" alt="Season" />
            <h2>
              Restoran: <a href="tel: +381 63 8373119">+381 63 8373119</a>
            </h2>
          </div>
          <div className="locationFooter">
            <img src="./images/timer.svg" alt="Season" />
            <div>
              <h2>Restoran: 09:00-22:00</h2>
            </div>
          </div>
          <div className="locationFooter">
            <img src="./images/phone.svg" alt="Season" />
            <h2>
              Prenočište: <a href="tel: +381 69 1845444">+381 69 1845444</a>
            </h2>
          </div>

          <div className="locationFooter">
            <img src="./images/timer.svg" alt="Season" />
            <div>
              <h2>Prenočište: 00:00-24:00</h2>
            </div>
          </div>
        </div>
        <div className="footerItem">
          <h1>Društvene mreže</h1>
          <div className="socialNetworks">
            <FacebookIcon style={{ color: "#3b5998" }} />
            <a
              href="https://www.facebook.com/trisesira.temerin/"
              target="_blank"
            >
              <h2> Tri Šešira Temerin </h2>
            </a>
          </div>
          <div className="socialNetworks">
            <InstagramIcon color="secondary" />
            <a
              href="https://www.instagram.com/trisesiratemerin/"
              target="_blank"
            >
              <h2> trisesiratemerin </h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
