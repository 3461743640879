import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { useEffect } from "react";
import useLoadingSpinner from "../hooks/useLoadingSpinner";

const listOfEvents = [
  {
    id: 1,
    name: "Januarski međunarodni sajam vina",
    description:
      "Festival je međunarodnog karaktera, pored domaćih vinara okuplja i vinare iz Mađarske, Hrvatske, Rumunije, Slovačke, Bugarske, Moldavije i Slovenije. Rekordan broj od 761 uzorka vina ostvaren je 2010. godine. Festival spada među najveće turističko-vinske manifestacije u Vojvodini, Srbiji, a i u Panonskoj niziji i okuplja nekoliko hiljada posetilaca, ljubitelja vina. Od 2011. godine ulazi u IPA program Evropske Unije. Sadržaji festivala: skup vinara iz Panonske nizije, skup međunarodnih i naših ocenjivača vina, skup vinarskih udruženja Srbije i okolnih zemalja, stručna degustacija vina.",
    img: "/images/sta-posetiti/sajam-vina.jpg",
    images: [{ original: "/images/sta-posetiti/sajam-vina.jpg" }],
  },
  {
    id: 2,
    name: "Dan opštine Temerin",
    description:
      "Povodom Dana opštine Temerin, koji se obeležava 1. jula, tradicionalno bude pripremljen bogat program. U 2022. godini povodom ovog dana u centru Temerina održao se Bazar koji je okupio razne izlagače rukotvorina i hrane, a pripremljen je bio i program za najmlađe posetioce – predstava “Jelen Trivutin” u izvođenju tima animatora “Slavi sa Slavicom”. Organizovana je i besplatna vožnja katamaranom “Čigra” u Parku prirode “Jegrička”. U večernjim satima u organizaciji Kulturnog centra “Lukijan Mušicki” na temerinskom vašarištu sve posetioce dočekao je poklon koncert Leksington benda.",
    img: "/images/sta-posetiti/dan-opstine-temerin.jpg",
    images: [{ original: "/images/sta-posetiti/dan-opstine-temerin.jpg" }],
  },
  {
    id: 3,
    name: "Pasuljijada",
    description:
      "Najveća manifestacija u opštini Temerin - najveće takmičenje u kuvanju pasulja u Srbiji! Svake godine se na prostoru prelepog temerinskog vašarišta okupi oko 200 ekipa iz regiona koji se takmiče u kuvanju kotlića pasulja na drva. Manifestacija obiluje dodatnim sadržajima kao što su: humanitarni ručak (veliki kazan sa oko 3000 porcija pasulja za posetioce koji se ne takmiče), ulica starih zanata, temerinske najzanimljivije igre, bogat kulturni i muzički program tokom celog dana, animatori i igre za najmlađе.",
    img: "/images/sta-posetiti/temerin-pasuljijada.jpg",
    images: [{ original: "/images/sta-posetiti/temerin-pasuljijada.jpg" }],
  },
  {
    id: 4,
    name: "Moto susret Temerin",
    description:
      "Moto skup u organizaciji moto kluba Patriots. U 2022. godini posetioce skupa su zabavljali grupe kao što su Yu Grupa, Alen Islamović i bend, Zorall bend, Rock Trajanja i Rockstarza bend. Program je trajao četiri dana - od srede do subote.",
    img: "/images/sta-posetiti/moto-skup.jpg",
    images: [{ original: "/images/sta-posetiti/moto-skup.jpg" }],
  },
  {
    id: 5,
    name: "Ilindanske svečanosti - ILLÉSNAP",
    description:
      "Tradicionalna proslava Ilindana na kojoj se organizuju izložbe ručnih radova, poljoprivrednih proizvoda, razne povorke kroz selo, posvećivanje novog hleba i igranke.",
    img: "/images/sta-posetiti/ilindanske-svecanosti.jpg",
    images: [{ original: "/images/sta-posetiti/ilindanske-svecanosti.jpg" }],
  },
  {
    id: 6,
    name: "Južnobački festival ludaja",
    description:
      "Održava se svake godine prvog četvrtka u oktobru mesecu. Prezentacija najraznovrsnijih bundeva i tikvi, ocenjivanje najtežih džinovskih i najdužih kobasičarskih bundeva i belih bundeva, ocenjivanje izloženih kompozicija od bundeva, izložba slikarskih radova školaraca na temu bundeva, takmičenje osnovnih škola u rezbarenju bundeva, degustacije tradicionalno pripremljenih peciva od bundeva.",
    img: "/images/sta-posetiti/festival-ludaja.jpg",
    images: [{ original: "/images/sta-posetiti/festival-ludaja.jpg" }],
  },
  {
    id: 7,
    name: "Novogodišnji mini vašar",
    description:
      "Temerin je poznat po dobroj i povoljnoj kupovini tokom cele godine, a u prazničnom raspoloženju robne kuće, butici, prodavnice poklanjaju svojim kupcima dodatne popuste, akcije, poklone. Novogodišnji mini vašar sastavni je deo ovog događaja. U samom centru u pešačkoj zoni svoja vina predstavljaju vinari iz naše opštine, udruženja žena svoje ručne radove i razne novogodišnje drangulije. Promoviše se “Januarski sajam vina” i deli kuvano vino za posetioce.",
    img: "/images/sta-posetiti/novogodisnji-mini-vasar.jpg",
    images: [{ original: "/images/sta-posetiti/novogodisnji-mini-vasar.jpg" }],
  },
  {
    id: 8,
    name: "Manifestacija “Zimske čarolije”",
    description:
      "Manifestacija koja je prvi put održana 2021. godine. Počinje krajem decembra i obuhvata mesec dana klizanja, predstava, radionica, koncerata... U centru Temerina bilo je postavljeno klizalište rasprostranjeno na 300m². Bio je obezbeđen bogat kulturni i zabavni program za decu i odrasle.",
    img: "/images/sta-posetiti/zimske-carolije.jpg",
    images: [{ original: "/images/sta-posetiti/zimske-carolije.jpg" }],
  },
];

const listOfPlaces = [
  {
    id: 1,
    name: "Park prirode Jegrička",
    description:
      "Na svega 2 km severno od Temerina nalazi se Informativni centar Parka prirode „Jegrička“, čiji je cilj da posetiocima omogući pružanje svih potrebnih informacija i usluga. Pejzaži prirode i info-centar privlače sve veći broj posetilaca i turista, željnih prirodnog okruženja, rekreacije, mira i tišine na obali reke. Ovde se može uživati u šetnjama stazama zdravlja i posmatranju ptica, voziti se katamaranom, drvenim čamcima… Mesto idealno za odmor od gradske gužve i vreve, od užurbanog načina života. Prostor info-centra koristi se za razne aktivnosti; pre svega za naučno-istraživački rad, prezentacije prirodnog dobra, edukacije, a ovde se održavaju i kulturne i sportske manifestacije, druženja, promocije itd. Programi za decu podrazumevaju sprovođenje jednodnevnih ekskurzija i edukativnih radionica u prirodi. Katamaran “Čigra” plovi od jula 2017. godine kroz prostor koji ima najveći stepen zaštite. Vožnja u trajanju od oko sat vremena startuje kod Informativnog centra Parka prirode „Jegrička” u Temerinu i vodi sve do osmatračnice blizu Žablja, gde se zainteresovanim turistima nudi i birdwatching.",
    img: "/images/sta-posetiti/jegricka.jpeg",
    images: [{ original: "/images/sta-posetiti/jegricka.jpeg" }],
  },
  {
    id: 2,
    name: "Bazenski kompleks",
    description:
      "Jedan od lepše uređenih bazenskih kompleksa u okruženju prostire se na 19.500 m2, a čine ga veliki olimpijski bazen i dva bazena sa termalnom vodom. Termalna voda crpi se sa 600 m dubine, a temperatura na izvorištu iznosi 37°C. Bogata je mineralima, jodnim i sumpornim jedinjenjima; svrstava se u red izuzetno lekovitih i blagotvornih voda. U efikasnom lečenju reumatskih, degenerativnih i kožnih oboljenja, kao i brzom zarastanju rana, termalna voda iz naših bazena, po tvrdnjama posetilaca, ne zaostaje po lekovitim svojstvima za vodama iz nadaleko poznatih banja iz susedne Republike Mađarske. Pored lekovitog svojstva geotermalne vode, posetiocima bazenskog kompleksa na raspolaganju su i mnogi rekreativni sadržaji. Pored tri izuzetno kvalitetna terena za odbojku na pesku, tu je i igralište sa potrebnim rekvizitima za zabavu najmlađih posetilaca (ljuljaške, klackalice, bazeni sa peskom). Posetioci mogu provesti prijatne trenutke u nekoliko ugostiteljskih bašti, gde ih očekuje bogata ponuda hrane i svih vrsta osvežavajućih pića. Bazen je otvoren u periodu od početka juna do polovine septembra, a radno vreme u toku dana je od 10 do 19 časova.",
    img: "/images/sta-posetiti/bazen.jpeg",
    images: [{ original: "/images/sta-posetiti/bazen.jpeg" }],
  },
  {
    id: 3,
    name: "Mini ZOO vrt",
    description:
      "Mini Zoo vrt u Temerinu ideja je i životno delo gospodina Karolja Kiša, koji je početkom osamdesetih godina počeo da radi na budućem vrtu. Mini Zoo vrt otvoren je 1991. godine i od tada svakodnevno uveseljava veliki broj posetilaca. Na prostoru vrta boravi oko 25 vrsta ptica močvarica, pavijan, vijetnamsko prase, zamorci, srne i još mnogo zanimljivih životinja. Ulaz se naplaćuje po principu dobrovoljnog priloga, a za organizovane posete većih grupa potrebna je prethodna najava.",
    img: "/images/sta-posetiti/zoo-vrt.jpeg",
    images: [{ original: "/images/sta-posetiti/zoo-vrt.jpeg" }],
  },
  {
    id: 4,
    name: "Bucin salaš",
    description:
      "Na 2 km udaljenosti od centra Temerina nalazi se Bucin salaš izgrađen u tradicionalnom stilu Bačke, sa domaćom kuhinjom i lokalnim specijalitetima. Pored restorana gostima su na raspolaganju dečije igralište, sportski tereni, jahanje konja, ekonomsko dvorište sa tipičnim životinjama za salaš. Postoji obezbeđen parking prostor za autobuse i automobile. Kapacitet restorana je oko 100 mesta u zatvorenom objektu i predivan autentičan dvorišni ambijent za ručavanje na otvorenom.",
    img: "/images/sta-posetiti/bucin-salas.jpeg",
    images: [{ original: "/images/sta-posetiti/bucin-salas.jpeg" }],
  },
  {
    id: 5,
    name: "Bio salaš Idei",
    description:
      "Bio salaš Idei, prava zelena oaza porodice Idei, nalazi se neposredno uz Park prirode Jegrička na Bečejskom putu. Sertifikovani su proizvođači organske hrane vrhunskog kvaliteta. Potreba za zdravim i zdravstveno bezbednim voćem i povrćem kao i potreba za očuvanjem životne sredine su osnova razvoja njihovog bio-baštovanstva. Uzgajaju preko 200 različitih vrsta voća i povrća, od čega dosta spada u specifične sorte – npr. batat, artičoke, bamija, rabarbara, beli patlidžan, habanero, 70 sorti paradajza… Sve se uzgaja na otvorenom polju na potpuno održiv i ekološki način, u skladu sa EU standardima, da bi hrana bila što ukusnija i zdravija.",
    img: "/images/sta-posetiti/bio-salas-idei.jpg",
    images: [{ original: "/images/sta-posetiti/bio-salas-idei.jpg" }],
  },
  {
    id: 6,
    name: "Antero salaš",
    description:
      "Antero etno salaš, raj za decu i roditelje, nalazi se na Bečejskom putu sa leve strane iz pravca Temerina, pre mosta na rečici Jegričkoj. Ovaj autentičan salašarski ambijent moguće je posetiti svakog dana u periodu od 11 časova. Izuzetno lep uređen prostor savršen je za organizaciju izleta, a moguća je i organizacija raznih vrsta događaja.",
    img: "/images/sta-posetiti/antero-salas.jpeg",
    images: [{ original: "/images/sta-posetiti/antero-salas.jpeg" }],
  },
  {
    id: 7,
    name: "Vinski salaš i vinarija Vindulo",
    description:
      "Vinarija Vindulo je porodična vinarija koja se nalazi na izlazu iz Temerina prema Bečeju. Vina su strast porodice Dujmović, a mnoga od njih su ovenčana i šampionskim peharima na takmičenjima u zemlji i inostranstvu. U širokom asortimanu vina izdvajaju se: Mirna Bačka, Pannonia, Rosanna, Three star i Slatka Eva. Pored proizvodnje vina, u vinariji Vindulo moguć je i obilazak vinograda i vinarije, upoznavanje sa procesom proizvodnje, degustacija vina uz odabrane bačke specijalitete i odličnu zabavu. Degustacija vina podrazumeva dve vrste ponude, a za 15 i više osoba se organizuje prema unapred zakazanim terminima. Zimski kapacitet sale za degustaciju je 70 osoba, dok leti autentično bačko dvorište i terasa može da primi oko 100 gostiju. Postoji obezbeđen parking za autobuse i automobile.",
    img: "/images/sta-posetiti/vinarija-vindulo.jpeg",
    images: [{ original: "/images/sta-posetiti/vinarija-vindulo.jpeg" }],
  },
  {
    id: 8,
    name: "Pčelarsko gazdinstvo Govedarica",
    description:
      "Pčelarsko gazdinstvo „Govedarica“ već godinama se bavi proizvodnjom meda i pčelinjih proizvoda. U svom asortimanu pored cvetnog meda, meda uljane repice, bagrema i suncokreta mogu se pronaći i proizvodi kao što su: propolis, voštani poklopci, med-polen-propolis, perga, vosak i sapun, ali i medenjaci i rakija medovača. U posedu porodice Govedarica nalazi se i nešto novo iz sveta pčelarstva. Radi se o API komori - kućici za inhalaciju u kojoj se nalazi više hiljada pčela. Udisanje vazduha iz API komore dovodi do značajnog poboljšanja kod određenih bolesti respiratnornih organa (astma, bronhitis), kao i kod poremećaja rada srca i kardio-vaskularnog sistema, alergija i imunološkog sistema. Program grupne posete pčelarskom domaćinstvu „Govedarica“ obuhvata: dolazak na gazdinstvo: upoznavanje sa pčelarstvom, šetnja kroz pčelinjak i i objašnjavanje rada sa pčelama, upoznavanje sa API komorom i degustacija više vrsta meda i mednih proizvoda. U okviru ture je moguće upoznati se sa radom i delotvornim svojstvima tretmana u API komori, a tretmane je neophodno prethodno najaviti/zakazati.",
    img: "/images/sta-posetiti/pg-govedarica.jpg",
    images: [{ original: "/images/sta-posetiti/pg-govedarica.jpg" }],
  },
  {
    id: 9,
    name: "Brvnara, Bački Jarak",
    description:
      "Etno-park “Brvnara” u Bačkom Jarku, jedinstveni muzej na otvorenom, smešten je uz glavnu saobraćajnicu na samom ulazu u naselje iz pravca Novog Sada. Zavičajna muzejska zbirka etnološko-istorijskog karaktera nastala je na osnovu inicijative stanovnika Bačkog Jarka, krajiških Srba, kolonista iz Bosanske Krajine. Ideja o njenom osnivanju potekla je 1946. godine, sa željom da se sačuva sećanje na zavičaj, a objekat je podignut 1978. godine. Centralni objekat ovog kompleksa je Brvnara, u kojoj se nalazi etnološki deo stalne postavke. Izgrađena je od brvana tako da podseća na tradicionalnu kuću dinarskog područja. Novu stalnu postavku realizovao je Muzej Vojvodine 2012. godine, a prikazani su originalni muzejski predmeti, istorijski dokumenti i foto-materijal, značajni za narodnu kulturu i istoriju Krajišnika.",
    img: "/images/sta-posetiti/brvnara.jpg",
    images: [{ original: "/images/sta-posetiti/brvnara.jpg" }],
  },
  {
    id: 10,
    name: "Zavičajna kuća TÁJHÁZ",
    description:
      "Zavičajna kuća u Temerinu otvorena je 2003. godine, a ovim objektom upravlja udruženje TAKT – Temerinska likovno stvaralačka kolonija. Od svog otvaranja pa do danas, zavičajnu kuću posetio je velik broj posetilaca, ali i umetnika, jer se na ovom prostoru održavaju brojne umetničke radionice i priredbe tokom godine. U dvorišnom prostoru zavičajne kuće izgrađena je Stvaralačka kuća, spratni objekat zamišljen tako da predstavlja svojevrstan dom umetnika TAKT-a, sa radionicom i galerijom, ali i smeštajnim delom predviđenim za boravak umetnika tokom trajanja brojnih umetničkih radionica i projekata. Izgradnjom Stvaralačke kuće stvaraju se uslovi za dostupnost postojećih i kreiranje novih sadržaja i usluga u okviru tematske celine TAKT u Temerinu. Aktivnosti koje se odvijaju na prostoru zavičajne kuće usmerene su na razvoj kulturnog turizma i kreativnog sektora u zajednici i regionu. U prvom redu, ciljna grupa jesu pojedinci i organizacije koji deluju u oblasti primenjenih umetnosti i kulturnog stvaralaštva.",
    img: "/images/sta-posetiti/tajhaz.jpg",
    images: [{ original: "/images/sta-posetiti/tajhaz.jpg" }],
  },
];

export default function ToVisit() {
  const [loader, showLoader, hideLoader] = useLoadingSpinner();

  useEffect(() => {
    showLoader();

    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  return (
    <>
      <div className="places">
        <div className="toVisitTitle">
          <h1>MESTA</h1>
        </div>
        <VerticalTimeline lineColor={"#960018"}>
          {listOfPlaces?.map((object) => {
            return (
              <VerticalTimelineElement
                key={object.id}
                className="vertical-timeline-element--work"
                contentStyle={{
                  border: "4px solid #960018",
                  background: "#ffff",
                  color: "black",
                }}
                contentArrowStyle={{ borderRight: "20px solid  #960018" }}
                iconStyle={{ background: "#960018", color: "#ffff" }}
                // icon={}
                date={object.description}
                dateClassName={"descriptionStyle"}
              >
                <h3 className="vertical-timeline-element-title">
                  {object.name}
                </h3>
                <p></p>
                <img src={object.img} height={300} width={450} />
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
      <div className="events">
        <div className="toVisitTitle">
          <h1>DOGAĐAJI</h1>
        </div>
        <VerticalTimeline lineColor={"#960018"}>
          {listOfEvents?.map((object) => {
            return (
              <VerticalTimelineElement
                key={object.id}
                className="vertical-timeline-element--work"
                contentStyle={{
                  border: "4px solid #960018",
                  background: "#ffff",
                  color: "black",
                }}
                contentArrowStyle={{ borderRight: "20px solid  #960018" }}
                iconStyle={{ background: "#960018", color: "#ffff" }}
                // icon={}
                date={object.description}
                dateClassName={"descriptionStyle"}
              >
                <h3 className="vertical-timeline-element-title">
                  {object.name}
                </h3>
                <p></p>
                <img src={object.img} height={300} width={450} />
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
      {loader}
    </>
  );
}
