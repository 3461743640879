import { useHistory } from "react-router";

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

export default function RedirectToGalleries() {
  const history = useHistory();

  const scrollFunction = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const redirectToRestaurantGallery = () => {
    history.push("/restoran");
    scrollFunction();
  };

  const redirectToRoomsGallery = () => {
    history.push("/prenociste");
    scrollFunction();
  };

  return (
    <div className="galleryRedirectWrapper">
      <div className="galleryRedirect">
        <h2 className="mobileGalleryText">
          <i>SAZNAJTE VIŠE</i>
        </h2>
        <div
          className="restaurantGallery"
          onClick={() => redirectToRestaurantGallery()}
        >
          <img src="/images/restoran/DSC_2643.jpg" alt="Restoran" />
          <p>RESTORAN</p>
        </div>
        <div className="galleryText">
          <ArrowLeftIcon color="primary" fontSize="large" />
          <h2>
            <i>SAZNAJTE VIŠE</i>
          </h2>
          <ArrowRightIcon color="primary" fontSize="large" />
        </div>
        <div className="roomsGallery" onClick={() => redirectToRoomsGallery()}>
          <img src="/images/prenociste/DSC_2497.jpg" alt="Prenociste" />
          <p>PRENOĆIŠTE</p>
        </div>
      </div>
    </div>
  );
}
