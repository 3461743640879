import RedirectToGalleries from "../components/RedirectToGalleries";
import ContactUs from "../components/ContactUs";
import DroneVideo from "../components/DroneVideo";
import HomePageGallery from "../components/HomePageGallery";

export default function HomePage() {
  return (
    <div className="homePage">
      <div className="backgroundColor">
        <div className="aboutUs">
          <h3>40 GODINA TRADICIJE</h3>
          <h2>
            <i>O NAMA</i>
          </h2>
          <p>
            <b>
              <i>Tri Šešira </i>
            </b>
            je restoran sa dugom tradicijom, osnovan daleke 1982. godine, koji u
            svojoj ponudi nudi specijalitete domaće kuhinje uz prijatan
            ambijent. Od 2008. godine uz restoran možemo da ponudimo i
            prenoćište našim gostima. Raspolažemo sa više vrsta soba različitih
            namena. Ključne reči koje opisuju naša prenoćišta su komfornost,
            klimatizovan prostor i besplatan pristup internetu. Možete nas
            pronaći na adresi Narodnog fronta 189 u Temerinu svakog dana od 8h
            do 22h dok su prenoćišta dostupna 00-24h. Sve dodatne informacije
            možete dobiti na brojeve telefona:
            <b> 063/1545767, 069/1845444</b> ili <b>021/844-906</b>.
          </p>
        </div>
      </div>
      {/* <div>
        <h2>
          <i>GOOGLE RECENZIJE</i>
        </h2>
      </div> */}
      <ContactUs />
      <RedirectToGalleries />
      <DroneVideo />
      <HomePageGallery />
    </div>
  );
}
