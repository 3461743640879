import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";

const images = [
  {
    original: "/images/restoran/DSC_2629.jpg",
  },
];

export default function HomePageGallery() {
  const scrollFunction = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="homePageGalleryWrapper">
      <div className="homePageGallery">
        <div className="homePageGalleryMobile">
          <h2>
            <i>GALERIJA</i>
          </h2>
        </div>
        <div className="imageGallery">
          <Link to="/galerija" onClick={scrollFunction}>
            <ImageGallery
              items={images}
              showBullets={false}
              showThumbnails={false}
              showPlayButton={false}
              showNav={false}
              lazyLoad={true}
              autoPlay={false}
              showFullscreenButton={false}
            />
          </Link>
        </div>
        <div className="homePageGalleryText">
          <h2>
            <i>GALERIJA</i>
          </h2>
          <p>
            Posetite našu galeriju i bolje se upoznajte sa izgledom našeg
            restorana, kuhinje, prenoćišta, parkinga i dvorišta.
          </p>
        </div>
      </div>
    </div>
  );
}
