import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spinnerWrapper: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: "11",
    top: "0",
    left: "0",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.spinnerWrapper}>
      <CircularProgress />
    </div>
  );
};

export default LoadingSpinner;
