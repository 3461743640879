import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { useEffect } from "react";
import useLoadingSpinner from "../hooks/useLoadingSpinner";

export default function Contact() {
  const [loader, showLoader, hideLoader] = useLoadingSpinner();

  useEffect(() => {
    showLoader();

    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  return (
    <div className="contactPage">
      <div className="contactPageTitle">
        <h1>KONTAKT INFORMACIJE</h1>
      </div>
      <div className="contactUs">
        <div className="contactUsTitle">
          <h2>
            <i>BROJEVI TELEFONA</i>
          </h2>
        </div>
        <div className="contactUsItems">
          <div className="phoneNumber">
            <div className="phoneIcon">
              <PhoneIcon color="primary" fontSize="large" />
            </div>
            <div className="phoneText">
              <p>RESTORAN</p>
              <b>
                <a href="tel: +381638373119">063 8373119</a>
              </b>
            </div>
          </div>
          <div className="phoneNumber">
            <div className="phoneIcon">
              <PhoneIcon color="primary" fontSize="large" />
            </div>
            <div className="phoneText">
              <p>PRENOĆIŠTE</p>
              <b>
                <a href="tel: 021 844906">021/844-906</a>
              </b>
            </div>
          </div>
          <div className="phoneNumber">
            <div className="phoneIcon">
              <PhoneIcon color="primary" fontSize="large" />
            </div>
            <div className="phoneText">
              <p>PRENOĆIŠTE</p>
              <b>
                <a href="tel: +381691845444">069 1845444</a>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="contactUs">
        <div className="contactUsTitle">
          <h2>
            <i>E-MAIL ADRESE</i>
          </h2>
        </div>
        <div className="contactUsItems">
          <div className="email">
            <div className="mailIcon">
              <EmailIcon color="primary" fontSize="large" />
            </div>
            <div className="mailText">
              <b>
                <a href="mailto:kontakt@trisesiratemerin.rs">
                  kontakt@trisesiratemerin.rs
                </a>
              </b>
            </div>
          </div>
          <div className="email">
            <div className="mailIcon">
              <EmailIcon color="primary" fontSize="large" />
            </div>
            <div className="mailText">
              <b>
                <a href="mailto:dejanbabiccc@gmail.com">
                  dejanbabiccc@gmail.com
                </a>
              </b>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
}
