import { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";

const useLoadingSpinner = () => {
  const [loading, setLoading] = useState(false);
  return [
    loading ? <LoadingSpinner /> : null,
    () => setLoading(true), //show spinner
    () => setLoading(false), //hide spinner
  ];
};

export default useLoadingSpinner;
