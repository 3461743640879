import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import useLoadingSpinner from "../hooks/useLoadingSpinner";

const images = [
  {
    original: "/images/prenociste/DSC_2624.jpg",
  },
  {
    original: "/images/prenociste/DSC_2604.jpg",
  },
  {
    original: "/images/prenociste/DSC_2513.jpg",
  },
  {
    original: "/images/prenociste/DSC_2518.jpg",
  },
  {
    original: "/images/prenociste/DSC_2519.jpg",
  },
  {
    original: "/images/prenociste/DSC_2529.jpg",
  },
  {
    original: "/images/prenociste/DSC_2553.jpg",
  },
  {
    original: "/images/prenociste/DSC_2485.jpg",
  },
  {
    original: "/images/prenociste/DSC_2497.jpg",
  },
  {
    original: "/images/prenociste/DSC_2503.jpg",
  },
  {
    original: "/images/prenociste/DSC_2480.jpg",
  },
  {
    original: "/images/prenociste/DSC_2561.jpg",
  },
  {
    original: "/images/prenociste/DSC_2566.jpg",
  },
  {
    original: "/images/prenociste/DSC_2579.jpg",
  },
  {
    original: "/images/prenociste/DSC_2589.jpg",
  },
];

export default function Restaurant() {
  const [loader, showLoader, hideLoader] = useLoadingSpinner();

  useEffect(() => {
    showLoader();

    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  return (
    <div className="rooms">
      <div className="roomsTitle">
        <h1>PRENOĆIŠTE</h1>
      </div>
      <div>
        <div className="description">
          <p>
            <b>Tri Šešira</b> je restoran sa dugom tradicijom, osnovan daleke
            1982. godine, koji u svojoj ponudi nudi specijalitete domaće kuhinje
            uz prijatan ambijent. Od 2008. godine uz restoran možemo da ponudimo
            i prenoćište našim gostima. Raspolažemo sa više vrsta soba
            različitih namena. Ključne reči koje opisuju naša prenoćišta su
            komfornost, klimatizovan prostor i besplatan pristup internetu.
            Moguće je dogovoriti doručak, ručak ili večeru u sklopu prenoćišta.
          </p>
          <p>
            Pored redovnog prenoćišta, u ponudi imamo i organizovanje smeštaja
            za veće grupe (radnici, sportisti, itd.). U godinama iza nas
            ugostili smo veliki broj sportskih ekipa, radnika i ostalih grupa, i
            verujemo da niko iz našeg restorana nije otišao nezadovoljan.
          </p>
          <p>
            Restoran sa prenoćištem <b>Tri Šešira</b> možete posetiti na adresi
            Narodnog fronta 189 u Temerinu svakog dana od 8h do 22h, dok su
            prenoćišta dostupna 00-24h. Sve dodatne informacije možete dobiti na
            sledeće brojeve telefona:{" "}
            <b>
              <a href="tel: +381631545767">063 1545767</a>
            </b>
            ,{" "}
            <b>
              <a href="tel: +381691845444">069 1845444</a>
            </b>{" "}
            ili{" "}
            <b>
              <a href="tel: 021 844906">021/844-906</a>
            </b>
            . Ispod možete pogledati našu galeriju i bolje se upoznati sa
            izgledom našeg prenoćišta.
          </p>
        </div>
        <div></div>
      </div>
      <div className="gallery">
        <div>
          <ImageGallery
            items={images}
            showBullets={true}
            showThumbnails={false}
            showPlayButton={false}
            lazyLoad={false}
          />
        </div>
      </div>
      {loader}
    </div>
  );
}
